<template>
  <!--begin::Card-->
  <div>
    <div class="form-group">
      <label>Code</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="form.code"
        placeholder="Voucher Code"
      />
      <span class="form-text text-muted">Enter unique voucher code</span>
    </div>
    <div class="form-group">
      <label>Aplicable For</label>
      <b-form-radio v-model="form.aplicablefor" value="all" selected> Both</b-form-radio>
      <b-form-radio v-model="form.aplicablefor" value="delivery" >Delivery</b-form-radio>
      <b-form-radio v-model="form.aplicablefor" value="collection">Collection</b-form-radio>
    </div>

    
    <div class="form-group">
      <label>For Specific Items</label>
      <b-form-checkbox v-model="form.applyforitems" >Choose items</b-form-checkbox>
    </div>
    <div class="form-group" v-if="form.applyforitems">
      <label>Products</label>
      <b-form-select
        v-model="form.items"
        :options="form.products"
        multiple
      ></b-form-select>
      <span class="form-text text-muted">
        Select Items to add discount on them only
      </span>
    </div>
    <div class="form-group" v-if="form.applyforitems">
      <b-form-checkbox v-model="form.discount_product_toppings">Apply Voucher on selected Product Toppings</b-form-checkbox>
      <b-form-checkbox v-model="form.discount_product_properties">Apply Voucher on selected Product Properties</b-form-checkbox>
    </div>
    <div class="form-group">
      <label>Discount</label>
      <b-form-radio v-model="form.discount" value="percentage" selected>Percentage</b-form-radio>
      <!-- <b-form-radio v-model="form.discount" value="product">Product (i.e free dish)</b-form-radio> -->
      <b-form-radio v-model="form.discount" value="amountoff">Amount off</b-form-radio>
    </div>
    <div class="form-group" v-if="form.discount == 'percentage'">
      <label>Percentage</label>
      <input
        min="0"
        max="100"
        type="number"
        class="form-control form-control-solid form-control-lg"
        v-model="form.percentage"
        placeholder="Enter Percentage"
      />
      <span class="form-text text-muted"
        >Enter percentage discount offered when using this voucher</span
      >
    </div>

    <div class="form-group" v-if="form.discount == 'amountoff'">
      <label>Amount off {{$store.state.auth.currency}}</label>
      <input
          min="1"
          type="number"
          class="form-control form-control-solid form-control-lg"
          v-model="form.amountoff"
          placeholder="Enter Amount Off"
      />
      <span class="form-text text-muted">
        Enter the amount of discount offered when using this voucher
      </span>
    </div>
    <div class="form-group">
      <label>Type</label>
      <b-form-radio v-model="form.type" value="date" selected>
        Date
      </b-form-radio>
      <b-form-radio v-model="form.type" value="onetime"> One off </b-form-radio>
    </div>
    <div class="form-group" v-if="form.type == 'date'">
      <label>Start Date</label>
      <b-form-datepicker
        v-model="form.start_date"
        locale="en"
      ></b-form-datepicker>
      <span class="form-text text-muted"
        >Select the date the voucher is valid from</span
      >
    </div>
    <div class="form-group" v-if="form.type == 'date'">
      <label>End Date</label>
      <b-form-datepicker
        v-model="form.end_date"
        locale="en"
      ></b-form-datepicker>
      <span class="form-text text-muted"
        >Select the date the voucher is valid until</span
      >
    </div>
    <div class="form-group" v-if="form.type == 'date'">
      <label>Counts</label>
      <input
          min="1"
          type="number"
          class="form-control form-control-solid form-control-lg"
          v-model="form.counts"
          placeholder="Vouchers can be used multiple times"
      />
      <span class="form-text text-muted">
        Enter the number for how many times the voucher can be used for single customer
      </span>
    </div>
    <div class="form-group">
      <label>Minimum Amount</label>
      <input
        min="0"
        type="number"
        class="form-control form-control-solid form-control-lg"
        v-model="form.minimum_amount"
        placeholder="Minimum Amount for this voucher"
      />
      <span class="form-text text-muted">
        Enter the minimum amount of orders that can apply the voucher code
      </span>
    </div>
    <hr />
    <div class="form-group" style="float: right">
      <b-button @click="cancel">Cancel</b-button> &nbsp;&nbsp;
      <b-button @click="save">Save</b-button>
    </div>
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";
import { SAVE_ANNOUNCEMENTS } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
export default {
  name: "AnnouncementForm",
  props: ["record"],
  data() {
    return {
      form: {
        
        discount: "percentage",
        type: "date",
        percentage: 0,
        code: "",
        start_date: "",
        end_date: "",
        minimum_amount: 0,
        announcement_id: "",
        products: [],
        items: [],
        counts : 0,
        amountoff : 0,
        discount_product_toppings : 0,
        discount_product_properties : 0,
        aplicablefor:"delivery",
        applyforitems:false,
      },
    };
  },
  methods: {
    getProducts() {
      this.form.products = [];
      ApiService.get("business/menu/products").then(({ data }) => {
        let arr = data.data;
        arr.forEach((topping) => {
          this.form.products.push({
            value: topping.id,
            text: topping.name,
          });
        });
      });
    },
    cancel() {},
    save() {
      if (!this.form.code) {
        Swal.fire("Validation!", "Code must be entered", "error");
        return;
      }

      this.$store
        .dispatch(SAVE_ANNOUNCEMENTS, this.form)
        .then((resp) => {
          if (resp.status === "Success") {
            Swal.fire("Information!", resp.message, "success");
            this.$emit("update");
            return;
          } else {
            Swal.fire("Error", resp.message, "error");
            return;
          }
        })
        .catch((error) => {
          if (error.data.status === "Error") {
            Swal.fire("Error", error.data.message, "error");
            return;
          }
          if (error.data.errors)
            Swal.fire("Error", JSON.stringify(error.data.errors), "error");
        });
    },
  },

  mounted() {
    if(this.record.announcement_id){
      console.log(this.record)
      this.form.announcement_id = this.record.announcement_id;
      this.form.code = this.record.code;
      this.form.discount = this.record.discount;
      this.form.aplicablefor=this.record.aplicablefor;
      this.form.type = this.record.type;
      this.form.percentage = this.record.percentage;
      this.form.start_date = this.record.start_date;
      this.form.end_date = this.record.end_date;
      this.form.minimum_amount = this.record.minimum;
      this.form.counts = this.record.count;
      this.form.amountoff = this.record.amountoff;
      this.form.discount_product_toppings = this.record.discount_product_toppings == 1 ? true : false;
      this.form.discount_product_properties = this.record.discount_product_properties == 1 ? true : false;
      this.form.items = JSON.parse(this.record.free_products);
      if(JSON.parse(this.record.free_products).length>0){
        this.form.applyforitems=true
      }
    }
    this.getProducts();
  },
};
</script>
